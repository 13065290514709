/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Link from '@editorjs/link';
import Hyperlink from 'editorjs-hyperlink';
import Code from '@editorjs/code';
//import CodeBox from '@bomdi/codebox';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import Warning from '@editorjs/warning';
import Table from '@editorjs/table';
import Image from '@editorjs/image';
import Undo from 'editorjs-undo';
import PageBreak from './editor-js-custom/page-break';



require('./bootstrap');

window.hljs = require('highlight.js');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

// Datepicker
const flatpickr = require('flatpickr');
$('.datepicker').each(function () {
    $(this).flatpickr({
        dateFormat: 'Y-m-d H:i:ss',
        altInput: true,
        altFormat: 'd/m/Y',
    });
});


// Decimal input type
$(function(){
    $("input[type=decimal]").attr('type', 'text').bind("change keyup input", function () {
        let position = this.selectionStart - 1;
        //remove all but number and .
        let fixed = this.value.replace(/[^0-9\.]/g, '');
        if (fixed.charAt(0) === '.') //can't start with .
            fixed = fixed.slice(1);

        let pos = fixed.indexOf(".") + 1;
        if (pos >= 0) //avoid more than one .
            fixed = fixed.substr(0, pos) + fixed.slice(pos).replace('.', '');

        if (this.value !== fixed) {
            this.value = fixed;
            this.selectionStart = position;
            this.selectionEnd = position;
        }
    });
});

// Editor.js
window.editors = [];
$('[data-editor]').each(function() {
    let id = $(this).data('editor');

    const undoConfig = {
        shortcuts: {
            undo: 'CMD+Z',
            redo: 'CMD+SHIFT+Z'
        }
    }

    let editorConfig = {

        onReady: () => {
            new Undo({ editor: window.editors[id], config: undoConfig });
        },
        /**
         * Id of Element that should contain the Editor
         */
        holder: 'editor-' + id,

        minHeight : 160,
        /**
         * Available Tools list.
         * Pass Tool's class or Settings object for each Tool you want to use
         */
        tools: {
            list: {
                class: List,
                inlineToolbar: true
            },
            code: {
                class: Code,
                inlineToolbar: true
            },
            // codeBox: {
            //     class: CodeBox,
            //     config: {
            //         themeURL: 'https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@9.18.1/build/styles/dracula.min.css', // Optional
            //         themeName: 'atom-one-dark', // Optional
            //         useDefaultTheme: 'light' // Optional. This also determines the background color of the language select drop-down
            //     }
            // },
            inline_code: {
                class: InlineCode,
                inlineToolbar: true
            },
            // warning: {
            //     class: Warning,
            //     inlineToolbar: true
            // },
            table: {
                class: Table,
                inlineToolbar: true
            },
            image: {
                class: Image,
                config: {
                    endpoints: {
                        byFile: '/consultant/projects/' + $('#project_id').val() + '/files',
                    }
                }
            },
            link: {
                class: Link,
                inlineToolbar: true,
                config: {
                    endpoint: '/fetch-url',
                }
            },
            hyperlink: {
                class: Hyperlink,
                config: {
                    shortcut: 'CMD+L',
                    target: '_blank',
                    rel: 'nofollow',
                    availableTargets: ['_blank', '_self'],
                    availableRels: ['author', 'noreferrer'],
                    validate: false,
                }
            },
            header: {
                class: Header,
                inlineToolbar: true
            },
            delimiter: {
                class: Delimiter,
                inlineToolbar: true
            },
            pageBreak: {
                class: PageBreak,
                inlineToolbar: true
            },
        },
    };

    if($(this).data('editor-data')) {
        editorConfig.data = $(this).data('editor-data');
    }

    window.editors[id] = new EditorJS(editorConfig);
});

async function saveEditor(i) {
    let data = await window.editors[i].save();
    let fieldName = window.editors[i].configuration.holder.substring(window.editors[i].configuration.holder.indexOf('-') + 1);
    $('input[name=' + fieldName + ']').val(JSON.stringify(data));
}

$('.editor-form').on('submit', function(e) {
    e.preventDefault();

    let promises = [];

    // Loop through window.editors object
    for (let i in window.editors) {
        promises.push(saveEditor(i));
    }

    // for(let i = 0; i < window.editors.length; i++) {
    //     promises.push(saveEditor(i));
    // }

    Promise.all(promises).then(() => {
        $(this).unbind('submit').submit();
    });
});

function tableParser(block){
    let toReturn = '<table class="table table-striped report-table">';
    let data = block.data.content;
    console.log(block)

    // loop through data as rows and columns and create a html table in toReturn
    for(let i = 0; i < data.length; i++){
        if(i === 0 && block.data.withHeadings){
            toReturn += '<thead>';
        }

        toReturn += '<tr>';
        for(let j = 0; j < data[i].length; j++){
            toReturn += '<td>' + data[i][j] + '</td>';
        }
        toReturn += '</tr>';

        if(i === 0 && block.data.withHeadings){
            toReturn += '</thead>';
        }
    }

    toReturn += '</table>';
    return toReturn;
}

function imageParser(block){
    let toReturn = '<div class="text-center"><div class="img-container">';
    toReturn += '<img src="' + block.data.file.url + '" class="img-fluid report-image ' + (block.data.caption? 'with-caption' : '')+ '" />';

    if(block.data.caption) {
        toReturn += '<p class="img-caption">' + block.data.caption + '</p>';
    }

    toReturn += '</div></div>';

    return toReturn;
}

function linkParser(block){
    return '<a href="' + block.data.link + '" target="_blank">' + block.data.link + '</a>';
}

function pageBreakParser(block){
    return '<div class="page-break"></div>';
}

const editorjsHTML = require("editorjs-html");
const edjsParser = editorjsHTML( {table: tableParser, image: imageParser, link: linkParser, pageBreak: pageBreakParser} );
$('.editor-content').each(function() {
    let json = $(this).find('.d-none').html();
    if(!json) {
        $(this).html(
            ($(this).data('heading') ? ('<h3>' + $(this).data('heading') + '</h3>') :'') +
            $('<textarea />').html('').text()
        );
        return;
    }
    let html = edjsParser.parse(JSON.parse(json));
    html = html.join('');


    $(this).html(
        ($(this).data('heading') ? ('<h3>' + $(this).data('heading') + '</h3>') :'') +
        $('<textarea />').html(html).text()
    );

    if($('#is_pdf').val() == 1) {
        $(this).find("> *:lt(2)").wrapAll("<div class='avoid-break'></div>");
        $(this).find("> *").unwrap();
    }

    window.hljs.highlightAll();
});

$('.wrap-first-two-items').each(function() {
    $(this).find("> *:lt(2)").wrapAll("<div class='avoid-break'></div>");
});
