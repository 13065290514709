/**
 * PageBreak for Editor.js.
 *
 * @typedef {object} PageBreakData
 * @description Tool's input and output data format
 * @property {boolean} divider — Add a divider line.
 */
export default class PageBreak {
    /**
     * @param {{data: object, api: object}}
     *   data — Previously saved data
     *   api - Editor.js API
     *   readOnly - read only mode flag
     */
    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly

        this.CSS = {
            block: this.api.styles.block,
            settingsButton: this.api.styles.settingsButton,
            settingsButtonActive: this.api.styles.settingsButtonActive,
            wrapper: 'page-break',
        };

        this.nodes = {
            wrapper: null,
            divider: null,
        };

        this.tuneButton = null;

        this._data = {
            divider: !!data.divider,
        };
    }

    /**
     * Renders Tool's view
     * @returns {HTMLDivElement}
     */
    render() {
        const wrapper = document.createElement('div');


        wrapper.classList.add(this.CSS.wrapper, this.CSS.block);

        this.nodes.wrapper = wrapper;

        wrapper.contentEditable = this.readOnly ? 'false' : 'true';

        return wrapper;
    }

    /**
     * Makes buttons with tunes.
     *
     * @returns {HTMLDivElement}
     */
    renderSettings() {
        // const tuneName = this.tune.name;
        // const wrapper = document.createElement('div');
        // const button = document.createElement('div');
        //
        // button.innerHTML = this.tune.icon;
        // button.dataset.tune = tuneName;
        // button.classList.add(this.CSS.settingsButton);
        // button.classList.toggle(this.CSS.settingsButtonActive, this.data[tuneName]);
        // button.addEventListener('click', () => this.tuneClicked(tuneName));
        //
        // this.tuneButton = button;
        // wrapper.appendChild(button);
        //
        // return wrapper;
    }

    /**
     * Clicks tune
     *
     * @param {string} tuneName Clicked tune name
     * @returns {void}
     */
    tuneClicked(tuneName) {
        this.tuneButton.classList.toggle(this.CSS.settingsButtonActive,
            !this.tuneButton.classList.contains(this.CSS.settingsButtonActive));

        this.tuneToggled(tuneName);
    }

    /**
     * Updates data and view when Block Tune is clicked
     *
     * @returns {void}
     */
    tuneToggled() {
        const tuneName = this.tune.name;
        const status = !this.data[tuneName];
        this.data = { [tuneName]: status };

        this.nodes.divider.classList.toggle(this.CSS.divider, status);
    }

    /**
     * Saves Block data
     * @returns {BreakLineData} - saved data
     */
    save() {
        return this.data;
    }

    /**
     * Gets current Tools`s data
     * @returns {BreakLineData} Current data
     */
    get data() {
        return this._data;
    }

    /**
     * Sets Tools`s data
     *
     * @param {BreakLineData} data — data to set
     */
    set data(data) {
        this._data = data || {};
    }

    /**
     * Icon and title for displaying at the Toolbox
     *
     * @returns {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="-0.5 -0.5 104 84">\n' +
                '  <g>\n' +
                '    <path d="M 36.75 2 L 74 23.5 L 36.75 45 L 36.75 34.5 C 29.71 34.5 24 40.21 24 47.25 C 24 54.29 29.71 60 36.75 60 L 102 60 L 102 82 L 36.75 82 C 17.56 82 2 66.44 2 47.25 C 2 28.06 17.56 12.5 36.75 12.5 Z" stroke-width="4" stroke-miterlimit="10" transform="rotate(-180,52,42)" pointer-events="all"></path>\n' +
                '  </g>\n' +
                '</svg>\n',
            title: 'Page Break',
        };
    }

    /**
     * Returns true to notify the core that read-only mode is supported
     *
     * @return {boolean}
     */
    static get isReadOnlySupported() {
        return true;
    }
}
